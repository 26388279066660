<template>
  <v-dialog
    v-model="dialogActiveState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn icon dark @click="dialogActiveState = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogActiveState = false"> Cerrar</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- ----------------------------------------------------- -->
      <v-container v-if="observations?.length < 1">
        <v-row class="my-15">
          <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
            <div class="mx-auto mb-4 pa-8 rounded-circle green lighten-4">
              <v-icon large color="success">mdi-check-circle-outline</v-icon>
            </div>
            <div>
              <p class="text-center font-weight-bold text-h6 mb-1">
                ¡Todo Bien!
              </p>
              <p class="text-center font-weight-medium text-body-1">
                No cuenta con observaciones y/o llamados de atención.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- ----------------------------------------------------- -->

      <!------------ Here goes the the tab container -------------->
      <v-container v-else>
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column justify-center align-center"
          >
            <p class="text-h4 mb-2 mt-4 text-center">Observaciones</p>
            <v-chip class="my-2" dense :color="`${colorTheme} lighten-4`">
              <span
                :class="`font-weight-bold text-body-1 mb-0 ${colorTheme}--text`"
                >{{ selectedStage?.name }}</span
              >
            </v-chip>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(observation, index) in observations"
                :key="index"
              >
                <v-expansion-panel-header v-slot="{ open }">
                  <v-row>
                    <!-- Observation Date -->

                    <v-col cols="12" class="black--text">
                      <v-fade-transition leave-absolute hide-on-leave>
                        <p
                          class="text-body-1 font-weight-medium mb-0"
                          v-if="!open"
                        >
                          {{
                            fullFormattedDate(
                              observation.date,
                              "dddd D [de] MMMM [del] YYYY"
                            )
                          }}
                        </p>
                        <v-row v-else>
                          <v-col cols="12">
                            <p
                              class="text-body-1 font-weight-bold text-left mb-0 blue--text text--darken-1"
                            >
                              {{ observation.user_data?.first_name }}
                              {{ observation.user_data?.last_name }}
                              <span class="d-none d-sm-inline">-</span>
                              <br class="d-sm-none" />
                              {{
                                fullFormattedDate(
                                  observation.date,
                                  "dddd D [de] MMMM [del] YYYY"
                                )
                              }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-card class="elevation-0 px-3">
                      <p class="text-justify mb-6 mt-2 text-body-1">
                        {{ observation.observation }}
                      </p>
                    </v-card>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ObservationsModal",
  props: {
    observations: {
      type: Array,
    },
    selectedStage: {
      type: Object,
    },
    colorTheme: {
      type: String,
    },
  },
  data() {
    return {
      dialogActiveState: false,
    };
  },
  methods: {
    toggleModal() {
      this.dialogActiveState = !this.dialogActiveState;
    },
  },
  computed: {
    allObservationsSorted() {
      // Checking if there are not undefined properties
      if (this.correctObservationsListStructure) {
        return this.mergeSort(this.observations, "date", "desc", "date");
      }

      // return this.observations;
    },

    // verifying main object is not null or undefined
    correctObservationsListStructure() {
      return this.observations !== undefined && this.observations !== null;
    },
  },
};
</script>