<template>
  <v-dialog
    v-model="dialogActiveState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn icon dark @click="dialogActiveState = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogActiveState = false"> Cerrar </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container v-if="allAssignedCodesSorted?.length < 1">
        <v-row class="my-15">
          <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
            <div class="mx-auto mb-4 pa-8 rounded-circle green lighten-4">
              <v-icon large color="success">mdi-check-circle-outline</v-icon>
            </div>
            <div>
              <p class="text-center font-weight-bold text-h6 mb-1">
                ¡Todo Bien!
              </p>
              <p class="text-center font-weight-medium text-body-1">
                No cuenta con llegadas tarde a la institución.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column justify-center align-center"
          >
            <p class="text-h4 mb-2 mt-4 text-center">Códigos asignados</p>
            <v-chip class="my-2" dense :color="`${colorTheme} lighten-4`">
              <span
                :class="`font-weight-bold text-body-1 mb-0 ${colorTheme}--text`"
                >{{ selectedStage?.name }}</span
              >
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <template>
              <v-card class="elevation-0">
                <v-card-title class="mb-6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    rounded
                    filled
                    dense
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  class="elevation-0"
                  elevation="0"
                  :headers="headers"
                  :items="allAssignedCodesSorted"
                  :search="search"
                  hide-default-footer
                  show-group-by
                  mobile-breakpoint="600"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  show-expand
                  item-key="id"
                  sort-by="studentAssignedCode.AssignedCodeDetail.Code.CodeType.name"
                  group-by="studentAssignedCode.AssignedCodeDetail.Code.CodeType.name"
                >
                  <template
                    v-slot:[`group.header`]="{ group, headers, toggle, isOpen }"
                  >
                    <td :colspan="headers.length">
                      <v-btn
                        @click="toggle"
                        small
                        icon
                        :ref="group"
                        :data-open="isOpen"
                      >
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>
                      <v-chip
                        dense
                        :color="`${setCodeTypeTheme(group).color} lighten-4`"
                      >
                        <v-icon :color="`${setCodeTypeTheme(group).color}`"
                          >mdi-card-account-details-outline</v-icon
                        >
                      </v-chip>

                      <span
                        class="blue-grey--text text--darken-3 font-weight-bold text-subtitle-1 mt-2 ml-2"
                        >Códigos
                        <span class="text-lowercase">{{ group }}s</span></span
                      >
                    </td>
                  </template>

                  <!-- Here goes the code -->
                  <template
                    v-slot:[`item.studentAssignedCode.AssignedCodeDetail.Code.name`]="{
                      item,
                    }"
                  >
                    <div
                      class="d-flex align-center justify-end justify-sm-start"
                    >
                      <v-icon
                        :color="
                          setCodeTypeTheme(
                            item.studentAssignedCode.AssignedCodeDetail.Code
                              .CodeType.name
                          ).color
                        "
                        class="d-none d-sm-inline"
                        x-large
                        left
                        >mdi-circle-small</v-icon
                      >
                      <p
                        class="font-weight-medium text-body-1 mb-0 text-wrap text-right text-sm-left ml-n2 py-2"
                        v-text="
                          item.studentAssignedCode.AssignedCodeDetail.Code.name
                        "
                      ></p>
                    </div>
                  </template>

                  <template v-slot:[`item.userDetails.full_name`]="{ item }">
                    <p class="font-weight-medium text-body-1 mb-0">
                      {{ item.userDetails.full_name }}
                    </p>
                  </template>

                  <template
                    v-slot:[`item.studentAssignedCode.AssignedCodeDetail.CodePlace.name`]="{
                      item,
                    }"
                  >
                    <p class="font-weight-medium text-body-1 mb-0">
                      {{
                        item.studentAssignedCode.AssignedCodeDetail.CodePlace
                          .name
                      }}
                    </p>
                  </template>

                  <!-- -------- Expansion -------- -->
                  <template v-slot:[`expanded-item`]="{ headers, item }">
                    <td :colspan="headers.length" class="pb-3">
                      <v-row class="mt-3">
                        <!-- ----------------------------------- -->
                        <v-col cols="12" sm="6" md="5">
                          <IconCard
                            title="Asignado el"
                            :subTitle="`${fullFormattedDate(
                              item.studentAssignedCode.AssignedCodeDetail
                                .created_at,
                              'dddd D [de] MMMM [del] YYYY [a las] hh:mm A'
                            )}.`"
                            icon="calendar-outline"
                            colorTheme="blue"
                          ></IconCard>
                        </v-col>
                        <!-- ----------------------------------- -->
                        <v-col cols="12" sm="3" md="4">
                          <IconCard
                            title="Asignado por"
                            :subTitle="`${item.userDetails.full_name}.`"
                            icon="account-outline"
                            colorTheme="blue"
                          ></IconCard>
                        </v-col>
                        <!-- ----------------------------------- -->
                        <v-col cols="12" sm="3" md="3">
                          <IconCard
                            title="Ubicación"
                            :subTitle="`${item.studentAssignedCode.AssignedCodeDetail.CodePlace.name}.`"
                            icon="map-marker-outline"
                            colorTheme="blue"
                          ></IconCard>
                        </v-col>
                        <!-- ----------------------------------- -->
                      </v-row>

                      <p
                        v-if="
                          item.studentAssignedCode.AssignedCodeDetail
                            .observation !== null
                        "
                        class="font-weight-bold text-h5 mt-3 grey--text text--darken-2 mb-n1 mt-1"
                      >
                        Observación de docente
                      </p>

                      <v-divider
                        v-if="
                          item.studentAssignedCode.AssignedCodeDetail
                            .observation !== null
                        "
                      ></v-divider>

                      <p
                        v-if="
                          item.studentAssignedCode.AssignedCodeDetail
                            .observation !== null
                        "
                        class="font-weight-normal my-1 text-body-1 text-justify"
                      >
                        {{
                          item.studentAssignedCode.AssignedCodeDetail
                            .observation
                        }}
                      </p>
                    </td>
                  </template>
                </v-data-table>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import IconCard from "@/view/components/dashboard/IconCard.vue";
export default {
  name: "AssignedCodesDetailsModal",
  components: {
    IconCard,
  },
  props: {
    assignedCodes: {
      type: Object,
    },
    selectedStage: {
      type: Object,
    },
    colorTheme: {
      type: String,
    },
  },
  data() {
    return {
      dialogActiveState: false,
      search: "",
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: "Código",
          value: "studentAssignedCode.AssignedCodeDetail.Code.name",
          groupable: false,
        },

        {
          text: "Asignado por",
          value: "userDetails.full_name",
          groupable: false,
        },

        {
          text: "Ubicación",
          value: "studentAssignedCode.AssignedCodeDetail.CodePlace.name",
          groupable: false,
        },

        {
          text: "Acción",
          groupable: false,
          value: "data-table-expand",
        },
      ],
    };
  },
  methods: {
    toggleModal() {
      this.dialogActiveState = !this.dialogActiveState;
    },

    isJustified(item) {
      return item.isJustified && item.isJustified != null;
    },
  },
  computed: {
    allAssignedCodesSorted() {
      let allRecords = [];

      //validating when undefined
      if (
        this.assignedCodes &&
        this.assignedCodes.aula &&
        this.assignedCodes.ex_aula
      ) {
        allRecords = this.assignedCodes.aula.concat(this.assignedCodes.ex_aula);
      }

      // Add a unique identifier to each record
      allRecords = allRecords.map((record, index) => {
        return { ...record, id: index };
      });

      return allRecords;
      // return this.mergeSort(
      //   allRecords,
      //   "correlative_severity_indicator",
      //   "asc",
      //   "correlative_severity_indicator"
      // );
    },
  },
};
</script>
