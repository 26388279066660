<template>
  <v-dialog
    v-model="showDialog"
    transition="dialog-bottom-transition"
    width="550"
  >
    <v-card>
      <v-container class="py-7" :class="`${colorTheme}`"></v-container>
      <v-container class="px-7">
        <p class="text-h4 font-weight-bold mb-1 d-flex align-center">
          {{titleLabel}}
          <v-icon :color="`${colorTheme}`" class="ml-2"
            >mdi-check-circle-outline</v-icon
          >
        </p>
        <p class="pt-0 mt-0">
          El día {{ formatDate(headerData?.created_at) }}.
        </p>
        <div class="mt-5">
          <p>
            Revisado y justificado por
            <span class="font-weight-bold">
              {{
                `${headerData.user_data?.first_name} ${headerData.user_data?.last_name}`
              }}
            </span>
            a las
            <span class="font-weight-bold">
              {{ `${formatTime(headerData.created_at)}.` }}
            </span>
          </p>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

export default {
  name: "JustificationHeaderModal",
  props: {
    colorTheme: {
      type: String,
      default: "success",
    },
    titleLabel: {
      type: String,
      default: "Justificado"
    },
    headerData: {
      type: Object,
    },
  },
  data: () => ({
    showDialog: false,
  }),
  methods: {
    toggleModal() {
      this.showDialog = !this.showDialog;
    },
    closeModal() {
      this.showDialog = false;
    },
    formatCompleteDate(date) {
      return dayjs(date).format("dddd, DD [de] MMMM YYYY [a las] HH:mm a");
    },
    formatDate(date) {
      return dayjs(date).format("dddd, DD [de] MMMM YYYY");
    },
    formatTime(date) {
      return dayjs(date).format("HH:mm a");
    },
  },
};
</script>