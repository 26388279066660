<template>
  <div
    class="d-flex flex-column justify-start align-center flex-md-row justify-md-start align-md-center my-4"
  >
    <v-avatar :color="`${colorTheme} lighten-4`" size="75">
      <v-icon dark :color="colorTheme"> mdi-{{ icon }} </v-icon>
    </v-avatar>

    <div
      class="d-flex flex-column justify-md-center align-md-start pt-2 pt-md-3 pl-md-4"
    >
      <p class="font-weight-bold text-h5 mb-0 text-center">
        {{ title }}
      </p>
      <p class="font-weight-medium text-body-1 text-center">
        {{ subTitle }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconCard",
  props: {
    colorTheme: {
      type: String,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
  },
};
</script>
