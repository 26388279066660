var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom card-stretch round-lg elevation-3"},[_c('div',{staticClass:"card-body d-flex flex-column"},[_c('div',{staticClass:"flex-grow-1 pb-5"},[_c('div',{staticClass:"d-flex align-items-center pr-2 mb-6"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('p',{staticClass:"blue-grey--text text--darken-1 font-weight-bold font-size-lg mb-0"},[_vm._v(" "+_vm._s(_vm.subjectTeacher)+" ")]),_c('span',{staticClass:"text-muted font-weight-bold font-size-md"},[_vm._v("hace "+_vm._s(_vm.subevaluationGradedTime))])]),_c('div',{staticClass:"symbol symbol-50"},[_c('span',{staticClass:"symbol-label bg-light-light"},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.subjectType[_vm.subjectTypeId].color},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.subjectType[_vm.subjectTypeId].color}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.subjectType[_vm.subjectTypeId].icon))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.subjectType[_vm.subjectTypeId].name))])])],1)])]),_c('a',{staticClass:"text-dark text-hover-primary font-size-h4",attrs:{"href":"#"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.activityCategoryName))]),_c('br'),_c('span',{staticClass:"text--secondary text-h6"},[_vm._v(_vm._s(_vm.subevaluationSubjectName))])]),_c('p',{staticClass:"text-dark-50 font-weight-normal font-size-lg mt-6"},[_vm._v(" "+_vm._s(_vm.subevaluationDescription)+" ")])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.scoreLevelColor(_vm.newSubevaluationScore, _vm.subjectTypeId)
            .backgroundColor},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":_vm.scoreLevelColor(_vm.newSubevaluationScore, _vm.subjectTypeId)
                .backgroundColor}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":_vm.scoreLevelColor(_vm.newSubevaluationScore, _vm.subjectTypeId)
                  .fontColor}},[_vm._v(" mdi-account-badge-outline ")]),_c('span',{class:`font-weight-bold ${
                  _vm.scoreLevelColor(_vm.newSubevaluationScore, _vm.subjectTypeId)
                    .fontColor
                }--text`},[_vm._v(_vm._s(_vm.newSubevaluationScore))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.subjectTypeId == _vm.MODULAR ? "Nivel de logro obtenido" : "Nota obtenida"))])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }