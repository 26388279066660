<template>
  <div
    class="card card-custom bgi-no-repeat gutter-b card-stretch"
    style="background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/abstract-4.svg)"
  >
    <!--begin::Body-->
    <div class="card-body">
      <a
        href="#"
        class="card-title font-weight-bold text-muted text-hover-primary font-size-h5"
        >Meeting Schedule</a
      >
      <div class="font-weight-bold text-success mt-9 mb-5">3:30PM - 4:20PM</div>
      <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">
        Craft a headline that is informative <br />and will capture readers
      </p>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "Widget14"
};
</script>
