<template>
  <div class="card card-custom card-stretch round-lg elevation-3">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column ">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <div class="d-flex align-items-center pr-2 mb-6">
          <div class="d-flex flex-column flex-grow-1">
            <p
              class="blue-grey--text text--darken-1 font-weight-bold font-size-lg mb-0"
            >
              {{ subjectTeacher }}
            </p>
            <span class="text-muted font-weight-bold font-size-md "
              >hace {{ subevaluationGradedTime }}</span
            >
          </div>

          <div class="symbol symbol-50">
            <span class="symbol-label bg-light-light">
              <v-tooltip bottom :color="subjectType[subjectTypeId].color">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="subjectType[subjectTypeId].color"
                    v-bind="attrs"
                    v-on="on"
                    >{{ subjectType[subjectTypeId].icon }}</v-icon
                  >
                </template>
                <span>{{ subjectType[subjectTypeId].name }}</span>
              </v-tooltip>
            </span>
          </div>
        </div>
        <!--end::Info-->
        <!--begin::Link-->
        <a href="#" class="text-dark text-hover-primary font-size-h4">
          <span class="font-weight-bold">{{ activityCategoryName }}</span>
          <br />
          <span class="text--secondary text-h6">{{
            subevaluationSubjectName
          }}</span>
        </a>
        <!--end::Link-->
        <!--begin::Desc-->
        <p class="text-dark-50 font-weight-normal font-size-lg mt-6">
          {{ subevaluationDescription }}
        </p>
        <!--end::Desc-->
      </div>
      <!--begin::Team-->

      <div class="d-flex align-items-center">
        <v-tooltip
          bottom
          :color="
            scoreLevelColor(newSubevaluationScore, subjectTypeId)
              .backgroundColor
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="ma-2"
              :color="
                scoreLevelColor(newSubevaluationScore, subjectTypeId)
                  .backgroundColor
              "
            >
              <v-icon
                left
                :color="
                  scoreLevelColor(newSubevaluationScore, subjectTypeId)
                    .fontColor
                "
              >
                mdi-account-badge-outline
              </v-icon>
              <span
                :class="
                  `font-weight-bold ${
                    scoreLevelColor(newSubevaluationScore, subjectTypeId)
                      .fontColor
                  }--text`
                "
                >{{ newSubevaluationScore }}</span
              >
            </v-chip>
          </template>
          <span>{{
            subjectTypeId == MODULAR
              ? "Nivel de logro obtenido"
              : "Nota obtenida"
          }}</span>
        </v-tooltip>
      </div>

      <!--end::Team-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "Widget11",
  data() {
    return {
      subjectType: {
        "1": {
          name: "Módulo",
          color: "light-green darken-1",
          icon: "mdi-cube-outline",
        },
        "2": {
          name: "Materia académica",
          color: "light-blue darken-1",
          icon: "mdi-human-male-board",
        },
      },
      MODULAR: 1,
      ACADEMIC: 2,
    };
  },
  props: {
    subEvaluation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    scoreLevelColor(score, evaluationType) {
      if (evaluationType == this.MODULAR) {
        if (score > 3.45) {
          return {
            backgroundColor: "success",
            fontColor: "white",
          };
        }

        return {
          backgroundColor: "red",
          fontColor: "white",
        };
      }
      if (evaluationType == this.ACADEMIC) {
        if (score < 5.5) {
          return {
            backgroundColor: "red",
            fontColor: "white",
          };
        }

        if (score > 5.4 && score < 6.0) {
          return {
            backgroundColor: "orange",
            fontColor: "black",
          };
        }

        if (score > 5.9) {
          return {
            backgroundColor: "success",
            fontColor: "white",
          };
        }
      }
    },
  },
  computed: {
    subjectTypeId() {
      return this.subEvaluation.subevaluation?.evaluation
        ?.activity_category_activity_type?.activity_category?.subject_type_id;
    },

    subjectTeacher() {
      return `${this.subEvaluation.user?.first_name} ${this.subEvaluation.user?.last_name}`;
    },

    activityCategoryId() {
      return this.subEvaluation.subevaluation?.evaluation
        ?.activity_category_activity_type?.activity_category?.id;
    },

    activityCategoryName() {
      return this.subEvaluation.subevaluation?.evaluation
        ?.activity_category_activity_type?.activity_category?.name;
    },

    subevaluationSubjectName() {
      return this.subEvaluation.subevaluation?.evaluation?.academic_load
        ?.subject?.name;
    },

    subevaluationDescription() {
      return this.subEvaluation.subevaluation?.description;
    },

    subevaluationGradedTime() {
      let currentDate = new Date();
      let specificDate = new Date("2023-07-01T19:13:10.000000Z");
      let difference = currentDate.getTime() - specificDate.getTime();
      let differenceInMinutes = difference / (1000 * 60);
      let differenceInHours = difference / (1000 * 60 * 60);
      let differenceInDays = difference / (1000 * 60 * 60 * 24);

      if (differenceInMinutes < 60) {
        return `Hace ${Math.round(differenceInMinutes)} minutos`;
      } else if (differenceInHours < 24) {
        return `Hace ${Math.round(differenceInHours)} horas`;
      } else {
        return `Hace ${Math.round(differenceInDays)} dias`;
      }
    },

    newSubevaluationScore() {
      return this.subEvaluation.score;
    },
  },
};
</script>
