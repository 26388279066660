<template>
  <div
    class="card card-custom bgi-no-repeat gutter-b card-stretch"
    style="background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/abstract-1.svg)"
  >
    <!--begin::body-->
    <div class="card-body">
      <a
        href="#"
        class="card-title font-weight-bold text-muted text-hover-primary font-size-h5"
        >New Release</a
      >
      <div class="font-weight-bold text-success mt-9 mb-5">ReactJS</div>
      <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">
        AirWays - A Front-end solution for <br />airlines build with ReactJS
      </p>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "Widget16"
};
</script>
