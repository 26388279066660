<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--------Loader-------->
    <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
      <div
        v-if="isLoading"
        class="d-flex align-center justify-center flex-column py-15"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="mb-4 mx-auto"
        ></v-progress-circular>
        <p class="text-body-1 font-weight-bold text-center">
          Cargando etapas disponibles...
        </p>
      </div>
    </v-scroll-x-transition>

    <!--------Discipline Stats-------->
    <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
      <div v-if="!isLoading" class="ma-0 pa-0">
        <!--begin::Header-->
        <div class="border-0 pt-5 d-flex justify-center">
          <div class="card-title font-weight-bold mb-6 mt-3">
            <div class="card-label text-center text-h4">
              Resumen de disciplina
              <div class="text-body-1 text-muted mt-1">Etapas disponibles</div>
            </div>
          </div>
        </div>
        <!--end::Header-->

        <!--begin::Body-->
        <div class="mb-2 d-flex justify-center">
          <v-btn-toggle
            class="d-none d-md-inline"
            ligth
            dense
            rounded
            mandatory
            v-model="toggleExclusive"
          >
            <v-btn
              :disabled="disciplineStats.areLoading"
              color="blue accent-4 "
              v-for="(stage, index) in sortedStages"
              :key="index"
            >
              <span class="white--text">{{ stage.name }}</span>
            </v-btn>
          </v-btn-toggle>
          <v-select
            :disabled="disciplineStats.areLoading"
            class="d-md-none mx-3"
            :items="sortedStages"
            item-text="name"
            filled
            rounded
            dense
            v-model="selectedStageInSelect"
            return-object
            @change="
              getDisciplineDetailsFromSelectedTimePeriod(selectedStageInSelect)
            "
            label="Etapa"
          >
            <template v-slot:item="{ item }">
              <p class="text-capitalize font-weight-medium text-body-1 mb-0">
                <v-icon :color="stageColor[sortedStages.indexOf(item) + 1]"
                  >mdi-numeric-{{
                    sortedStages.indexOf(item) + 1
                  }}-circle-outline</v-icon
                >
                <span class="ml-2 mb-n1">{{ item.name }}</span>
              </p>
            </template>
          </v-select>
        </div>

        <div v-if="toggleExclusive != undefined" class="pa-5">
          <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
            <v-row v-if="disciplineStats.areLoading">
              <v-col cols="12">
                <div
                  class="d-flex align-center justify-center flex-column py-15"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="mb-4 mx-auto"
                  ></v-progress-circular>
                  <p class="text-body-1 font-weight-bold text-center">
                    buscando expediente...
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-scroll-x-transition>

          <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
            <v-row
              v-if="!disciplineStats.areLoading"
              class="d-flex justify-center justify-md-start align-center flex-row pb-5"
            >
              <!-- ---------------------Codes card-------------------- -->

              <v-col cols="11" md="6" class="d-flex justify-center flex-column"
                ><IconCardWithActionButtonVertical
                  :title="assignedCodesCard.title"
                  :subTitle="assignedCodesCard.subtitle"
                  :icon="assignedCodesCard.icon"
                  :colorTheme="assignedCodesCard.theme"
                  @openDetailsModal="showMdlAssignedCodesModal()"
                ></IconCardWithActionButtonVertical>
              </v-col>

              <!-- ---------------------Observations card-------------------- -->

              <v-col cols="11" md="6" class="d-flex justify-center flex-column"
                ><IconCardWithActionButtonVertical
                  :title="observationsCard.title"
                  :subTitle="observationsCard.subtitle"
                  :icon="observationsCard.icon"
                  :colorTheme="observationsCard.theme"
                  @openDetailsModal="showMdlObservationsModal()"
                ></IconCardWithActionButtonVertical>
              </v-col>

              <!-- -------------Late arrivals card------------- -->
              <v-col cols="11" md="6" class="d-flex justify-center flex-column"
                ><IconCardWithActionButtonVertical
                  :title="lateArrivalsCard.title"
                  :subTitle="lateArrivalsCard.subtitle"
                  :icon="lateArrivalsCard.icon"
                  :colorTheme="lateArrivalsCard.theme"
                  @openDetailsModal="showMdlLateArrivalsModal()"
                ></IconCardWithActionButtonVertical>
              </v-col>
              <!-- -------------Absences card------------- -->

              <v-col cols="11" md="6" class="d-flex justify-center flex-column"
                ><IconCardWithActionButtonVertical
                  :title="absencesCard.title"
                  :subTitle="absencesCard.subtitle"
                  :icon="absencesCard.icon"
                  :colorTheme="absencesCard.theme"
                  @openDetailsModal="showMdlAbsencesModal()"
                ></IconCardWithActionButtonVertical>
              </v-col>
            </v-row>
          </v-scroll-x-transition>
        </div>

        <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
          <div v-if="toggleExclusive == undefined" class="pa-5">
            seleccione una etapa
          </div>
        </v-scroll-x-transition>

        <!--end::Body-->
      </div>
    </v-scroll-x-transition>

    <LateArrivalsDetailsModal
      ref="mdlLateArrivalsDetailedList"
      :lateArrivals="this.disciplineStats.data?.late_arrivals"
      :selectedStage="selectedStage"
      :colorTheme="`${
        amountOfUnjustifiedLateArrivalsInTotal < 1 ? 'green' : 'red'
      }`"
    ></LateArrivalsDetailsModal>

    <AbsencesDetailsModal
      ref="mdlAbsencesDetailedList"
      :missingClasses="this.disciplineStats.data?.absences"
      :selectedStage="selectedStage"
      :colorTheme="`${
        amountOfUnjustifiedAbsencesInTotal < 1 ? 'green' : 'deep-orange'
      }`"
    ></AbsencesDetailsModal>

    <AssignedCodesDetailsModal
      ref="mdlAssignedCodesDetailedList"
      :assignedCodes="this.disciplineStats.data?.assigned_codes"
      :selectedStage="selectedStage"
      colorTheme="deep-orange"
    ></AssignedCodesDetailsModal>

    <ObservationsModal
      ref="mdlObservationsList"
      :observations="this.disciplineStats.data?.observations"
      :selectedStage="selectedStage"
      colorTheme="deep-purple"
    >
    </ObservationsModal>
  </div>
</template>

<script>
import LateArrivalsDetailsModal from "@/view/components/modals/student_discipline/LateArrivalsDetailsModal.vue";
import AbsencesDetailsModal from "@/view/components/modals/student_discipline/AbsencesDetailsModal.vue";
import AssignedCodesDetailsModal from "@/view/components/modals/student_discipline/AssignedCodesDetailsModal.vue";
import ObservationsModal from "@/view/components/modals/student_discipline/ObservationsDetailsModal.vue";
import dashboardRepository from "@/repositories/dashboardRepository";
import IconCardWithActionButtonVertical from "@/view/components/dashboard/IconCardWithActionButtonVertical.vue";
import { mapGetters } from "vuex";

export default {
  name: "widget-4",
  components: {
    IconCardWithActionButtonVertical,
    LateArrivalsDetailsModal,
    AbsencesDetailsModal,
    AssignedCodesDetailsModal,
    ObservationsModal,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
    sortedStages: {
      type: Array,
    },
    activeStage: {
      type: Object,
    },
  },

  data() {
    return {
      selectedStageInSelect: {},
      toggleExclusive: undefined,
      disciplineStats: {
        areLoading: false,
        data: {},
      },
      stageColor: {
        1: "blue lighten-1",
        2: "blue darken-2",
        3: "blue darken-4",
        4: "indigo darken-4",
        5: "deep-purple darken-4",
      },
    };
  },
  watch: {
    /*Because the request of the sorted stages take a random
    amount of time everytime, only until theres a list of
    stages, the request to fetch the discipline stats is made.*/
    sortedStages(newValue) {
      if (newValue.length > 0) {
        this.getDisciplineDetailsFromTimePeriod(this.activeStage);
        this.toggleExclusive = this.activeStage.indexInSortedList;
        this.selectedStageInSelect = this.sortedStages.find((item) => {
          return item.id == this.activeStage.indexInSortedList + 1;
        });
      }
    },

    /*Theres the need to use a watcher for this variable
    because the component provided by vuetify takes longer
    to update the value of the v-model variable than the
    function that finds the selected stage from the sorted list.*/
    toggleExclusive(newValue) {
      const individualStage = this.sortedStages[newValue];
      if (individualStage) {
        this.selectedStageInSelect = individualStage;
        this.getDisciplineDetailsFromClickedTimePeriod(individualStage);
      }
    },
  },

  methods: {
    getDisciplineDetailsFromSelectedTimePeriod(stage) {
      /* The stages id's range between 1 and 4 but
       the toglleExclusive indexes range from 0 to 3.
       That's why the subtraction happens.*/
      this.toggleExclusive = this.sortedStages.indexOf(stage);
      // this.toggleExclusive = this.selectedStageInSelect.id - 1;
      this.getDisciplineDetailsFromTimePeriod(stage);
    },

    getDisciplineDetailsFromClickedTimePeriod(stage) {
      //just to keep code consistency
      this.getDisciplineDetailsFromTimePeriod(stage);
    },

    getDisciplineDetailsFromTimePeriod(stage) {
      this.disciplineStats.areLoading = true;
      this.disciplineStats.data = {};

      dashboardRepository
        .getStudentDisciplineDetailsByTimePeriod(
          this.currentUserPersonalInfo.uuid,
          stage.start,
          stage.end
        )
        .then(({ data }) => {
          this.disciplineStats.data = data.data;
        })
        .catch((error) => {
          console.log("errorr ", error);
        })
        .finally(() => {
          this.disciplineStats.areLoading = false;
        });
    },

    // --------------------Modals--------------------
    showMdlLateArrivalsModal() {
      this.$refs.mdlLateArrivalsDetailedList.toggleModal();
    },
    showMdlAbsencesModal() {
      this.$refs.mdlAbsencesDetailedList.toggleModal();
    },
    showMdlAssignedCodesModal() {
      this.$refs.mdlAssignedCodesDetailedList.toggleModal();
    },
    showMdlObservationsModal() {
      this.$refs.mdlObservationsList.toggleModal();
    },
  },

  computed: {
    ...mapGetters(["layoutConfig", "currentUserPersonalInfo"]),
    selectedStage() {
      if (!!this.sortedStages) {
        return this.sortedStages[this.toggleExclusive];
      }
    },

    // ---------------------Late arrivals (school and classroom)--------------------
    amountOfLateArrivalsToClass() {
      return (
        this.disciplineStats.data?.late_arrivals?.to_class?.unjustified
          ?.length +
        this.disciplineStats.data?.late_arrivals?.to_class?.justified?.length
      );
    },
    amountOfLateArrivalsToSchool() {
      return (
        this.disciplineStats.data?.late_arrivals?.to_school?.unjustified
          ?.length +
        this.disciplineStats.data?.late_arrivals?.to_school?.justified?.length
      );
    },

    amountOfUnjustifiedLateArrivalsToClass() {
      return this.disciplineStats.data?.late_arrivals?.to_class?.unjustified
        ?.length;
    },
    amountOfUnjustifiedLateArrivalsToSchool() {
      return this.disciplineStats.data?.late_arrivals?.to_school?.unjustified
        ?.length;
    },

    amountOfUnjustifiedLateArrivalsInTotal() {
      return (
        this.amountOfUnjustifiedLateArrivalsToSchool +
        this.amountOfUnjustifiedLateArrivalsToClass
      );
    },

    lateArrivalsCard() {
      return {
        title: "Llegadas tarde",
        subtitle: `${
          this.amountOfUnjustifiedLateArrivalsInTotal > 0
            ? `Tiene ${this.amountOfUnjustifiedLateArrivalsInTotal} sin justificar`
            : `No hay llegadas tarde sin justificar`
        }`,
        theme: `${
          this.amountOfUnjustifiedLateArrivalsInTotal < 1 ? "green" : "red"
        }`,
        icon: `${
          this.amountOfUnjustifiedLateArrivalsInTotal < 1
            ? "check-circle-outline"
            : "book-clock-outline"
        }`,
      };
    },

    // ---------------------Absences (school and classroom)--------------------

    amountOfAbsencesToClass() {
      return (
        this.disciplineStats.data?.absences?.to_class?.unjustified?.length +
        this.disciplineStats.data?.absences?.to_class?.justified?.length
      );
    },
    amountOfAbsencesToSchool() {
      //not provided by the api yet
      return (
        this.disciplineStats.data?.absences?.to_school?.unjustified?.length +
        this.disciplineStats.data?.absences?.to_school?.justified?.length
      );
    },

    amountOfUnjustifiedAbsencesToClass() {
      return this.disciplineStats.data?.absences?.to_class?.unjustified?.length;
    },
    amountOfUnjustifiedAbsencesToSchool() {
      //not provided by the api yet
      return this.disciplineStats.data?.absences?.to_school?.unjustified
        ?.length;
    },

    amountOfUnjustifiedAbsencesInTotal() {
      return (
        this.amountOfUnjustifiedAbsencesToClass +
        this.amountOfUnjustifiedAbsencesToSchool
      );
    },

    absencesCard() {
      return {
        title: `Ausencias`,
        subtitle: `${
          this.amountOfUnjustifiedAbsencesInTotal > 0
            ? `Tiene ${this.amountOfUnjustifiedAbsencesInTotal} sin justificar`
            : `No hay ausencias sin justificar`
        }`,
        theme: `${
          this.amountOfUnjustifiedAbsencesInTotal < 1 ? "green" : "deep-orange"
        }`,
        icon: `${
          this.amountOfUnjustifiedAbsencesInTotal < 1
            ? "check-circle-outline"
            : "account-minus-outline"
        }`,
      };
    },

    //----------------------------Codes (ex-aula and aula)----------------------------

    amountOfExaulaCodes() {
      return this.disciplineStats.data?.assigned_codes?.ex_aula?.length;
    },
    amountOfAulaCodes() {
      return this.disciplineStats.data?.assigned_codes?.aula?.length;
    },

    amountOfAssignedCodesInTotal() {
      return this.amountOfExaulaCodes + this.amountOfAulaCodes;
    },

    /*
    The card will only change colors and show something bad when the student recived a negative code and the color of the car will only show for 24 hours.

    if the aplied code is positive it wont change color because is positive == green

    the other option is that the codes and nurse visit card, all will have a preset color because those are not things that need justification.
    */

    assignedCodesCard() {
      return {
        title: `Códigos`,
        subtitle: `Positivos y negativos, aula y ex-aula`,
        icon: `${
          this.amountOfAssignedCodesInTotal < 1
            ? "check-circle-outline"
            : "gavel"
        }`,
        theme: `deep-orange`,
      };
    },

    //----------------------------Observations----------------------------

    amountOfObservations() {
      return this.disciplineStats.data?.observations?.length;
    },

    observationsCard() {
      return {
        title: `Observaciones`,
        subtitle: `${this.amountOfObservations} Llamados de atención y citatorios`,
        icon: `${
          this.amountOfObservations < 1
            ? "check-circle-outline"
            : "file-document-alert-outline"
        }`,
        theme: `${this.amountOfObservations < 1 ? "green" : "deep-purple"}`,
      };
    },
  },
};
</script>
