<template>
  <div class="d-flex flex-column align-center my-2 px-3">
    <v-avatar :color="`${colorTheme} lighten-4`" size="80">
      <v-icon dark :color="colorTheme"> mdi-{{ icon }} </v-icon>
    </v-avatar>

    <!-- <div
      class="d-flex flex-column justify-md-center align-md-start pt-2 pt-md-3 pl-md-4"
    > -->
    <v-row class="mt-5 justify-center align-center">
      <v-col cols="12" class="ma-0 pa-0">
        <p class="font-weight-bold text-h5 mb-1 text-center">
          {{ title }}
        </p>

        <p
          class="font-weight-medium text--secondary text-body-1 text-center mb-0"
        >
          {{ subTitle }}
        </p>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0 mt-5 px-3">
        <v-btn
          dense
          small
          rounded
          outlined
          block
          :color="`${colorTheme}`"
          @click="openDetailsModal()"
          class="mt-3 mt-md-0 white--text"
        >
          {{ buttonText }}
          <v-icon right dark> mdi-arrow-right-circle </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "IconCardWithActionButtonVertical",
  props: {
    colorTheme: {
      type: String,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    buttonText: {
      type: String,
      default: "Ver detalle",
    },
  },
  methods: {
    openDetailsModal() {
      this.$emit("openDetailsModal");
    },
  },
};
</script>
